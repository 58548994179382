.Editor {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  position: relative;
  padding: 15px;
  gap: 15px;
  font-family: "Helvetica"!important;
}
.EditorLetter {
  position: relative;
  border: 1px solid #aaa;
  box-shadow: 2px 2px 4px #aaa;
  width: 210mm;
  min-height: 297mm;
  padding: 25mm 0 20mm;
  font-size: 10pt;
  background-color: #fff;
  white-space: pre-line;
  /*
  background-image: url("../../../assets/A4Page.png");
  background-repeat: repeat;
  */
}
.EditorLetterPage {
  position: relative;
  height: 252mm;
  background-color: transparent;
  border-top: .5px dashed #ccc;
}
.EditorLetterPage::after {
  position: absolute;
  left: 4mm;
  content: "";
  width: 4mm;
  bottom: 65mm;
  height: 105mm;
  border-top: .75px solid #000;
  border-bottom: .5px solid #000;
}
.EditorLetterPage::before {
  position: absolute;
  left: 0;
  content: "";
  width: 8mm;
  top: 123.5mm;
  border-top: 1px solid #000;
}
.EditorLetterPageInfo {
  left: 0;
  bottom: -7px;
  height: 14px;
  position: absolute;
	background: #ccc;
  font-size: 10px;
  line-height: 10px;
  padding: 2px 4px;
  z-index: 1;
}
.EditorLetterPageInfo:after {
	left: 100%;
	top: 50%;
	border: solid transparent;
	content: "";
	height: 0;
	width: 0;
	position: absolute;
	pointer-events: none;
	border-color: transparent;
	border-left-color: #ccc;
	border-width: 7px;
	margin-top: -7px;
}
.EditorLetterOverlay {
  position: absolute;
  left: 20mm;
  right: 10mm;
  top: 0;
  min-height: 250mm;
}
.EditorToolbox {
  position: sticky;
  top: 75px;
  flex: 1 0 0;
  background-color: #0076ba17;
  border-radius: 5px;
  padding: 10px;

}
.EditorToolboxClose {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 10px;
  right: 10px;
  width: 18px;
  height: 18px;
  font-size: 16px;
  color: #fff;
  background-color: #dc3545;
  cursor: pointer;
}
.EditorStyleButton, .EditorPrintButton {
  position: absolute;
  top: 0;
  left: 0;
  height: 15mm;
  width: 15mm;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10mm;
  color: #fff;
  background-color: #ccc;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  z-index: 9;
}
.EditorPrintButton {
  left: 15mm;
}
.EditorStyleButton:hover, .EditorStyleButton.active {
  background-color: #0076ba;
}
.EditorPrintButton:hover, .EditorPrintButton.active {
  background-color: var(--accent1);
}
.EditorLetterReply {
  position: absolute;
  height: 5mm;
  width: 80mm;
  top: 50mm;
  left: 5mm;
  display: flex;
  align-items: flex-end;
  font-size: 6pt;
}
.EditorLetterRecipient {
  position: absolute;
  padding-top: 10mm;
  height: 45mm;
  width: 80mm;
  left: 5mm;
  top: 55mm;
}
.EditorLetterSender {
  position: absolute;
  height: 45mm;
  width: 75mm;
  right: 0;
  top: 55mm;
  display: flex;
  align-items: flex-end;
}
.EditorLetterTitle {
  position: relative;
  margin-top: calc(100mm + 22pt);
  width: 100%;
  line-height: 13pt;
  font-weight: bold;
}
.EditorLetterHeadertext {
  margin-top:13pt;
  position: relative;
  width: 100%;
}
.EditorLetterPositions {
  position: relative;
  width: 100%;
  margin: 12pt 0;
}
.EditorTablePosId {
  vertical-align: top;
  position: relative;
}
.EditorTableAddBefore, .EditorTableAddAfter, .EditorTableMoveUp, .EditorTableMoveDown {
  position: absolute;
  margin:0;
  top: 0;
  right: 0;
  height: 50%;
  width: 50%;
  max-height: 24px;
  display: flex;
  font-size: 18px;
  justify-content: center;
  align-items: center;
  background-color: #0076ba;
  color: #fff;
  opacity: 0;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}
.EditorTableDelete {
  position: absolute;
  margin:0;
  top: 0;
  right: 0;
  height: 24px;
  width: 36px;
  max-height: 24px;
  display: flex;
  font-size: 18px;
  justify-content: center;
  align-items: center;
  background-color: #dc3545;
  color: #fff;
  opacity: 0;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}
.EditorTableAddAfter {
  bottom: 0;
  top: unset;
}
.EditorTableMoveUp {
  left: 0;
  right: unset;
}
.EditorTableMoveDown {
  left: 0;
  bottom: 0;
  right: unset;
  top: unset;
}
.EditorTableAddAfter:hover, .EditorTableAddBefore:hover, .EditorTableMoveUp:hover, .EditorTableMoveDown:hover, .EditorTableDelete:hover{
  opacity: 1;
}
.EditorTableAddNew:hover {
  height: 14px;
}
.EditorTable {
  width: 100%;
}
.EditorTable > tbody > tr > td {
  border-bottom: 1px solid #ccc;
}
.EditorTable > tbody > tr:last-child > td {
  border-bottom: 1.75px solid #000;
}
.EditorTable > thead > tr > th {
  font-weight: 400;
  border-bottom: 1.75px solid #000;
}
.EditorTableNumbers {
  vertical-align: bottom;
  text-align: right;
}
.EditorTableNumbers > span {
  position: absolute;
  right: 0;
  bottom: 0;
  white-space: nowrap;
}
.EditorLetterFootertext {
  position: relative;
  width: 100%;
}
.EditorLetterFooter {
  position: absolute;
  bottom: 2mm;
  font-size: 8pt;
  left: 20mm;
  right: 10mm;
  height: 18mm;
  gap: 2mm;
  display: flex;
  border-top: .5px solid #ccc!important;
  overflow: hidden;
}
.EditorLetterFooter > div {
  flex: 1 0 0;
}
.EditorClickable {
  border: .5px solid transparent;
  cursor: pointer;
  transition: border-color .15s ease-in-out;
}
.EditorClickable:hover {
  border-color: #0076ba88;
}
.EditorClickable.active {
  border-color: #0076ba;
}
.EditorTablePositions > td {
  background-color: #fff;
  cursor: pointer;
  position: relative;
}
.EditorTablePositions.active > td {
  background-color: #0076ba17!important;
  cursor: default!important;
}
.EditorTablePositions:hover > td{
  background-color: #0076ba09;
}