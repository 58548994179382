:root {
  --white: #ffffff;
  --light: #eeeeee;
  --dark: #14161e;
  --primary: #0076ba;
  --secondary:  #1d96d1;
  --info: #53bfed;
  --accent1: #1db100;
  --accent2: #00cc39;
  --accent3: #7de47d;
  --success: #00c56d;
  --warning: #ffc107;
  --danger: #dc3545;
}
.pageTitle {
  font-weight: 500;
  margin-top: 1rem !important;
  margin-bottom: 1rem !important; 
}
input[type="date"].form-control-sm, input[type="time"].form-control-sm, input[type="datetime"].form-control-sm, input[type="number"].form-control-sm {
  height: 31px;
}
input:not(.form-control-sm)[type="date"], input:not(.form-control-sm)[type="time"], input:not(.form-control-sm)[type="datetime"], input:not(.form-control-sm)[type="number"] {
  height: 38px;
}
.modal-header {
  font-weight: 600;
}
.inputEuro {
  text-align: right!important;
  -moz-appearance: textfield;
}
.inputEuro::-webkit-outer-spin-button,
.inputEuro::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.hidePos > tr > td:first-of-type {
  color: transparent !important;
}