:root {
  --white: #ffffff;
  --light: #eeeeee;
  --dark: #14161e;
  --primary: #0076ba;
  --secondary:  #1d96d1;
  --info: #53bfed;
  --accent1: #1db100;
  --accent2: #00cc39;
  --accent3: #7de47d;
  --success: #00c56d;
  --warning: #eed300;
  --danger:  #ff1e1c;
}
html, body {
  min-height: 100vh;
}
body {
  position: relative;
  padding: 70px 15px 15px;
}
body::after, body::before {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 12px;
  content: "";
  background: linear-gradient(0deg, var(--accent1) 0%, var(--primary) 100%);
  background-size: 100vw 100vh;
  background-attachment: fixed;
}
body::after {
  right: 0;
}
body::before {
  left: 0;
}
@media screen and (min-width: 768px) {
  body { padding: 70px 25px 25px; }
  body::after, body::before { width: 15px; }
}
a {
  text-decoration: none!important;
}
body, p, span {
  cursor: default;
}

/* Für Tabellenelemente */
table, th, td {
  cursor: default;
}
span.Werk {
  font-weight: 600;
  color: var(--primary);
}
span.APP {
  color: var(--accent1);
  font-weight: 600;
}
.errorAccordion {
  border-color: rgb(241, 174, 181)!important;
}
.errorAccordion > h2 > .accordion-button {
  background-color: rgb(248, 215, 218)!important;
}
.accordion-button {
  font-weight: 600;
}
.link {
  font-size: 18px;
  cursor: pointer;
  margin-right:10px;
  color: var(--primary);
}
.link:hover {
  color: var(--dark);
}
.fileIcon {
  display: inline-block;
  position: relative;
  cursor: pointer;
  width: 36px;
}
.fileIcon > svg {
  font-size: 32px;
}
.fileIcon > p {
  position: absolute;
  height: 16px;
  text-transform: uppercase;
  top: calc(50% - 4px);
  left:0;
  font-size: 10px;
  font-weight: 600;
  line-height: 1;
  padding: 3px;
  border-radius: 3px;
  background-color: var(--dark);
  color: #fff;
}
.fileIcon.pdf > p {
  background-color: var(--danger);
  color: #fff;
}
.fileIcon.doc > p,
.fileIcon.docx > p {
  background-color: var(--primary);
  color: #fff;
}
.fileIcon.xls > p,
.fileIcon.xlsx > p,
.fileIcon.csv > p,
.fileIcon.numbers > p {
  background-color: var(--accent1);
  color: #fff;
}
.fileIcon.jpg > p,
.fileIcon.jpeg > p,
.fileIcon.pages > p {
  background-color: var(--warning);
  color: #000;
}
.fileIcon.png > p,
.fileIcon.gif > p {
  background-color: var(--accent3);
  color: #000;
}
.fileIcon.ppt > p,
.fileIcon.pptx > p,
.fileIcon.keynote > p {
  background-color: var(--secondary);
  color: #fff;
}
.fileIcon.ppt > p,
.fileIcon.pptx > p,
.fileIcon.keynote > p {
  background-color: var(--secondary);
  color: #fff;
}
.fileIcon.dwg > p,
.fileIcon.dxf > p,
.fileIcon.dxb > p,
.fileIcon.dwf > p {
  background-color: var(--info);
  color: #fff;
}
table {
  font-size: 0.875rem;
}
.overLayLink {
  color: var(--primary);
  cursor: pointer;
  margin-right: .5em;
}
.overLayLink:hover { color: var(--dark); }
.firebase-emulator-warning {
  opacity: .3;
}