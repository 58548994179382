.dispoBoardDay {
  padding:0 .15rem!important;
}
.dispoBoardTaskCard {
  
  background-color: #ffecb5;
  border-radius: .325rem;
  font-size: .8rem;
  margin:.15rem 0;
  padding:.15rem;
}
.dispoBoardTaskCardTitle {
  font-weight: 600;
  padding-left: 5px;
  margin:0;
}
.dispoBoardStaffDrop {
  border: 1px dashed #dddddd;
  background-color: #fff3cd;
  min-height: 50px;
}

.table-container {
  overflow:auto;
  border:1px solid var(--bs-border-color);
}
.table-container > table {
  width: 100%;
  height: 100%;
  margin:-1px;
}
.fixedPositionTop {
  position:sticky;
  background-color: #ffffff !important;
  top: 0;
  z-index: 1;
  padding:0 .15rem!important;
  text-align: center;
}
.fixedPositionTop::after {
  position:absolute;
  right:0;
  bottom:0;
  width:100%;
  background-color: var(--bs-border-color) !important;
  content: "";
  height: 1px;
}
.fixedPositionLeft {
  position:sticky;
  background-color: #ffffff !important;
  left: 0;
  z-index: 2;
  padding:0 .15rem!important;
}
.fixedPositionLeft::after {
  position:absolute;
  right:0;
  top:0;
  width:1px;
  background-color: var(--bs-border-color) !important;
  content: "";
  height: 100%;
}