.waList {
  display: block;
  border:1px solid rgba(0, 118, 186, 0.1);
  border-radius: 7px;
  overflow: hidden;
}
.waListHeader {
  background-color: #fff;
}
.waListHeaderControl {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  background-color: rgba(0, 118, 186, 0.1);
  padding: 5px;
}
.waListHeaderControl > div {
  display: flex;
}
.waListRowHeader {
  display: flex;
  font-weight: 600;
  flex-wrap: wrap;
  padding: 3px;
}
.waListRowHeader > div {
  white-space: nowrap;
  padding: 3px 10px 3px 0;
}
.waListItem > span {
  cursor: pointer;
}
.waListItem > span > svg {
  margin-left: .5em;
}
.waListRow {
  display: flex;
  padding: 3px;
  flex-direction: column;
  transition: background-color 0.3s;
}
.waListRow:nth-child(odd) {
  background-color: rgba(0, 118, 186, 0.05);
}
.waListRow:hover {
  background-color:rgba(0, 118, 186, 0.1);
}
.waListItem {
  flex: 1 0 0;
  padding: 0;
}
.waListInfo {
  margin: 15px;
  padding: 40px 15px;
  text-align: center;
  border-radius: 7px;
  background-color: rgba(0, 118, 186, 0.1);
}
.inputGroup > :first-child {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  margin-right: -1px;
}
.inputGroup > :not(:last-child):not(:first-child) {
  border-radius: 0;
  margin-right: -1px;
}
.inputGroup > :last-child {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.waListBody {
  height: calc(100vh - 300px);
  overflow: auto;
  font-size: 0.9rem;
}
.waListFooter {
  padding: 5px 0;
  text-align: center;
  font-size: .8rem;
  color: rgba(0, 0, 0, 0.5);
  border-top: 1px solid rgba(0, 118, 186, 0.1);
}
.waListBtns {
  flex: 1 0 0;
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  align-items: center;
}

.waEdit div {
  flex: 1 0 0;
  gap: 15px 25px;
}
.waEdit > div {
  display: flex;
}
.waEdit > div > div {
  flex: 1 0 0;
  display: flex;
  flex-direction: column;
}
.waEdit > div > div > div {
  flex: 1 0 0;
  display: flex;
  flex-direction: row;
}
.waEditBtn {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 15px;
  margin: 15px 0;
}
.formGroup {
  flex: 1 0 0;
  display: flex;
  flex-direction: column!important;
  gap: 3px!important;
}
.formGroup > p {
  margin:0;
  font-size: .9rem;
  color: rgba(0, 0, 0, 0.5);
}
.formRequired {
  margin-left: .2em;
  color: #dc3545;
}
@media screen and (min-width: 1024px) {
  .waListHeaderControl {
    flex-direction: row;
    padding: 5px 15px;
  }
  .waListRowHeader {
    padding: 0;
  }
  .waListRowHeader > div {
    flex: 1 0 0;
    padding: 10px 3px;
  }
  .waListRow {
    flex-direction: row;
    flex-wrap: wrap;
  }
  .waListItem {
    padding: 10px 3px;
  }
}