.measurementsTable > tbody > tr > td {
  padding: 1px;
}
.measurementLine:hover {
  background-color: #0076ba10;
}
.measurementHeaderSmall {
  font-size: 0.875rem;
  color: #999;
  margin: 0;
  text-align: right;
}
.measurementNumberGrey {
  color: #ddd;
}
@media screen and (min-width: 768px) {
  .measurementHeaderSmall {
    display: none;
  }
}