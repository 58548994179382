.menu {
  position: fixed;
  top:0;
  left: 0;
  right: 0;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
  z-index: 10;
  padding:0 25px;
}
.menuLogo {
  position: relative;
  font-size: 1.6rem;
  font-weight: 700;
  color: var(--primary);
}
.menuLogo:hover {
  color: var(--primary);
}
.menuLogo > span {
  cursor: pointer;
  color: var(--accent1);
}
.menuLogoSubtitle {
  position: absolute;
  top: 1.6rem;
  left: 5px;
  width: 200px;
  font-size: .75rem;
  font-weight: 500;
  color: #404040;
  cursor: pointer;
}
.menuLinks {
  display: none;
}
@media screen and (min-width: 1024px) {
  .menuLinks {
    display: flex;
    gap: 5px;
    align-items: center;
  }
  .mobileMenuBtn {
    display: none!important;
  }
}

.menuNav {
  position: relative;
}
.menuNavBtn {
  border-radius: 10px;
  padding: 10px 15px;
  cursor: pointer;
  font-weight: 500;
  color: var(--primary);
  margin:0;
  line-height: 1;
}
.menuNavBtn:hover {
  background-color:rgb(248, 249, 250);
}
.menuNavBtn:hover ~ div.menuSubmenu {
  display: block;
}
.menuLogo:hover ~ div.menuMainSubmenu {
  display: block;
}
div.menuSubmenu:hover, div.menuMainSubmenu:hover {
  display: block;
}
div.menuMainSubmenu {
  position: absolute;
  display: none;
  padding: 15px;
  left:-15px;
  top: 45px;
  background-color: white;
  border-radius: 15px;
  z-index:30;
  box-shadow: 4px 4px 8px #3333;
  width: 600px;
  column-count: 2;
  column-gap: 15px;
  animation: fadeIn .5s ease-in-out forwards;
}
div.menuSubmenu {
  position: absolute;
  display: none;
  padding: 15px;
  right:-15px;
  top: 36px;
  background-color: white;
  border-radius: 15px;
  z-index:30;
  box-shadow: 4px 4px 8px #3333;
  width: 600px;
  column-count: 2;
  column-gap: 15px;
  animation: fadeIn .5s ease-in-out forwards;
}
div.menuSubmenu.oneRow {
  width: 300px;
  column-count: 1;
}
.menuSubmenu > h2 {
  color: var(--primary);
  font-size: 18px;
}
.menuSubBtn {
  display: block;
  align-items: center;
  text-decoration: none;
  border-radius: 10px;
  padding: 5px 10px;
  color: var(--primary);
  font-size:16px;
  font-weight:600;
  -webkit-column-break-inside: avoid;
  page-break-inside: avoid;
  break-inside: avoid;
  cursor: pointer;
}
.menuSubBtnSmall {
  display: block;
  font-size:12px;
  font-weight: 400;
  opacity: .8;
  color: var(--dark);
  cursor: pointer;
}
.menuSubBtn:hover, .menuSubBtn:focus, .menuSubBtn.active {
  background-color: rgb(248, 249, 250);
}
@keyframes fadeIn {
  0% { opacity:0 }
  100% { opacity:1}
}
.autoLogoutTimer {
  font-size: 12px;
  text-align: left;
  width: 60px;
  margin-right: -10px;
  cursor: pointer;
}
.menuLink {
  font-weight: 500;
}
.menuLink:hover {
  color: #14161e;
}
