.newsPrev {
  display: block;
  background-color: #fff;
  box-shadow: 1px 1px 6px #0076ba66;
  padding: 10px;
  cursor: pointer;
  transition: all .3s;
}
.newsPrev.active {
  box-shadow: 4px 4px 14px #1db10099;
}
.newsPrevTitle {
  font-weight: 600;
  cursor: pointer;
  margin: 0 0 .3em;
}
.newsPrevText {
  font-size: .875rem;
  hyphens: auto;
  cursor: pointer;
  margin: 0;
}
.newsFull {
  display: block;
  background-color: #fff;
  box-shadow: 4px 4px 14px #1db10099;
  padding: 15px;
  transition: all .3s;
}
.newsFullTitle {
  font-weight: 600;
  font-size: 1.2rem;
  margin: 0 0 .3em;
}
.newsFullIntro {
  font-size: .875rem;
  hyphens: auto;
  margin: 0 0 .5em;
  padding: 0 30px;
}
.newsFull ul {
  font-size: .875rem;
}