.plannerTD {
  padding: 0!important;
}
.plannerDay {
  display: flex;
  height:35px;
  margin-left: -1px;
  margin-right: -1px;
  flex-direction: column;
  justify-content: stretch;
  align-items: stretch;
}
.plannerTab {
  flex:1 0 0;
  cursor: pointer;
  border:0.5px solid #111;
}
.plannerTab:hover {
  transform: scale(1.1);
}